import { StandardFilesTranslateAction } from "../actions";
import { AppDispatch, RootState } from "../store/store";
import { Middleware } from "redux";
import { ITUConnector } from "../connector/ITUConnector";
import { languagesActions } from "../reducers/languages.slice";
import { fileActions, selectAllFile } from "../reducers/file.slice";
import {
    ChangePdfTypeAction,
    CheckEmailAction,
    ConvertFileAction,
    CostEstimation,
    CreateProjectAction,
    OrderNextStepAction,
    SetAnimationClassAction,
    ShowErrorAction,
    ShowFileSizePopUpAction,
    SubscribePlanAction,
    UserRateAction
} from "../actions/actionsTypes";
import { Dispatch } from "react";
import {
    CheckUserWordsAllowanceResponse,
    ConvertFileRequest,
    MailRequest,
    PdfType,
    PrepareStripeCheckoutRequest,
    RegisterNewUserMail,
    RegisterUserRequest,
    RegistrationSource,
    SubscriptionBuyRequest,
    UserType
} from "../connector/models/apiModels";
import { AxiosError } from "axios";
import { ErrorType, TaxTypeUpdate, User } from "../types/StandardFilesTranslateTypes";
import { controlActions, generateCreateProjectAction, generateShowErrorAction } from "../reducers/control.slice";
import {
    ACCOUNT_WAS_CREATED,
    CHECKING_EMAIL,
    CREATING_ACCOUNT,
    DIFFERENT_LANGUAGE_WARNING,
    HUMAN_TRANSLATION_AND_REVISION,
    INTERNAL_SERVER_ERROR,
    MACHINE_TRANSLATION_AND_REVIEW,
    MACHINE_TRANSLATION_NAME,
    PREPARING_COST_ESTIMATION,
    PREPARING_MACHINE_TRANSLATION,
    PROJECT_OFFER_REQUIRED,
    REDIRECT_TO_STRIPE
} from "../editorConstants";
import { MiddlewareAPI } from "@reduxjs/toolkit";
import { userActions } from "../reducers/user.slice";
import Enumerable from "linq";
import Cookies from "universal-cookie";
import { clearCookies } from "../services/addCurrentEditorState";
import { saveAs } from "file-saver";
import { convertCostEstimationToString } from "../helpers/ConvertCostEstimationToString";
import { hubConnection } from "../index";
import { getProjectCostEstimation, removeSessionStorageItems } from "../helpers/RedirectHelpers";
//@ts-ignore
import { gtag } from "ga-gtag";
import { getStoredValue } from "../helpers/EditorHelpers";
import { subscriptionActions } from "../reducers/subscription.slice";
import { projectsActions } from "../reducers/projects.slice";
import { purchaseEvent } from "../services/gtagService";
import * as Sentry from "@sentry/react";

export class ApiMiddleware {
    conn: ITUConnector;

    constructor(connector: ITUConnector) {
        this.conn = connector;
    }

    middleware: Middleware = (api) => (next: Dispatch<StandardFilesTranslateAction>) => async (action: StandardFilesTranslateAction) => {

        if (action.type !== undefined) {
            const store = api.getState() as RootState;
            const cookies = new Cookies();

            switch (action.type) {
                case "projects/generateFetchOrdersListAction": {
                    await this.conn.getProjectList().then((resp) => {
                        const filteredProjects = Enumerable.from(resp.data).where((x) => x.serviceName != "Machine Translation" && x.fileSource == 2);
                        next(projectsActions.addProject(filteredProjects.toArray()));
                    });
                    break;
                }
                case "subscription/generateGetSubscriptionDataAction": {
                    if (sessionStorage.getItem("token") != null) {
                        await this.conn.checkUserWordsAllowance().then((resp) => {
                            if (Enumerable.from(["Anonymous Open Editor", "Membership", "Anonymous TUFT", "Basic", "Pro", "Free plan", "Free TextUnited Membership"]).any((x) => x == resp.data.subscriptionName)) {
                                if (resp.data.subscriptionName != "Anonymous Open Editor") {
                                    next(subscriptionActions.setSubData(resp.data));
                                }
                            } else {
                                sessionStorage.clear();
                                document.location.href = "https://www.textunited.com/my/";
                            }
                        }).catch((err: AxiosError) => {
                            if (err.response?.status == 400 || err.response?.status == 500) {
                            }
                            //api.dispatch(controlActions.showFileLoadingPopUp(false));
                        });
                    }
                    break;
                }
                case "subscription/generateSubscriptionCancelAction": {
                    await this.conn.subscriptionClose().then((resp) => {
                        if (resp.status == 200) {
                            this.conn.checkUserWordsAllowance().then((resp) => {
                                next(subscriptionActions.setSubData(resp.data));
                            });
                        }
                    }).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {
                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });
                    break;
                }
                case "users/generateGetUserData": {

                    await this.conn.getCountries().then((r) => {
                        if (r.data != undefined && store.user.countries.length == 0) {
                            const index = r.data!.findIndex((x) => x.countryName == "UNDEFINED");
                            const countries = Enumerable.from(r.data).where((x) => x.countryName != "UNDEFINED");
                            api.dispatch(userActions.setCountries(countries.toArray()));
                        }
                    }).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {

                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });
                 
                    await this.conn.getUserData().then((resp) => {
                        next(userActions.setUserData(resp.data));
                        next(userActions.setCountryId(resp.data.company.address!.countryId));
                    }).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {

                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });

                    await this.conn.getCompanyPreferences().then((resp) => {
                        next(
                            userActions.setTaxInfo({
                                taxCode: resp.data.preferenceProperties?.taxType?.stripeCode ?? "",
                                taxNumber: resp.data.preferenceProperties.taxNumber
                            })
                        );
                    }).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {

                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });


                   
                    break;
                }
                case "control/generateCheckRedirectAction": {
                    /*Checking if user was redirected from another step while trying to log-in*/
                    if (getStoredValue("guid")) {
                        const guid = sessionStorage.getItem("guid")!;
                        next(fileActions.setProjectId(guid));
                        next(controlActions.showFileDownloadingModal(true));
                        await this.downloadMachineTranslationFile(api).then(() => {
                            controlActions.showFileDownloadingModal(false);
                        }).catch(() => {
                            controlActions.showFileDownloadingModal(false);
                        });
                    }
                    if (getStoredValue("step")) {
                        // next(fileActions.loading(true));
                        const step = parseInt(sessionStorage.getItem("step") as string);
                        const projectId = sessionStorage.getItem("projectId");
                        const anonymous = sessionStorage.getItem("anonymous");
                        const words = sessionStorage.getItem("words");
                        const pages = sessionStorage.getItem("pdfPages");
                        if (sessionStorage.getItem("sourceLang") != null) {
                            const targetLang = sessionStorage.getItem("targetLang")!.split(",");
                            const sourceLang = sessionStorage.getItem("sourceLang")!;
                            next(languagesActions.addSourceLanguage(sourceLang!));
                            next(languagesActions.addTargetLanguage(targetLang.map((x) => x.toLowerCase())));
                        }

                        api.dispatch(fileActions.setProjectId(projectId!));
                        if (words != "null") {
                            next(fileActions.setTotalWordsCount(Number(words)));
                        }
                        if (pages != "null") {
                            next(fileActions.setPdfPagesNumber(Number(pages)));
                        }

                        /* Send Company Id to microservice in case if user wasn't logged in and create project as anonymous */
                        if (store.file.projectId != "") {
                            await this.conn.setCompanyId(projectId!);
                        }

                        /*If user check email method return true, so we should redirect him to Stripe checkout*/
                        const account = Boolean(cookies.get("account"));
                        if (account && step == 4) {
                            api.dispatch(controlActions.setRedirectFromStripe(true));
                            api.dispatch(controlActions.setLoadingScreenMessage(REDIRECT_TO_STRIPE));
                            await this.createStripeCheckout(api, cookies.get("service")).then((r) => api.dispatch(controlActions.setRedirectFromStripe(false)));
                            return;
                        }
                        /*Check if user was redirected from cost estimation step, and setting cost estimation data */
                        if (sessionStorage.getItem("targetLang") != "" && step == 2) {
                            const targetLang = sessionStorage.getItem("targetLang")!.split(",");
                            const sourceLang = sessionStorage.getItem("sourceLang")!;
                            next(languagesActions.addSourceLanguage(sourceLang!));
                            next(languagesActions.addTargetLanguage(targetLang.map((x) => x.toLowerCase())));
                            await this.conn
                                .getCostEstimation({
                                    projectId: projectId!,
                                    targetLanguageCodes: targetLang.map((x) => x.toLowerCase()),
                                    sourceLanguageCode: sourceLang.toLowerCase()
                                })
                                .then((r) => api.dispatch(fileActions.addCostEstimation(r.data)))
                                .then(() => {
                                    api.dispatch(languagesActions.addSourceLang(sourceLang));
                                    api.dispatch(languagesActions.addTargetLanguage(targetLang));
                                }).catch((err: AxiosError) => {
                                    if (err.response?.status == 400 || err.response?.status == 500) {

                                    }
                                    //api.dispatch(controlActions.showFileLoadingPopUp(false));
                                });

                            api.dispatch(controlActions.setActiveStep(step));
                        }
                        /*Check if user was logged in from check email step and have an email, so we redirect him to Stripe checkout page */
                        if (step == 3 && anonymous == "false") {
                            const service = sessionStorage.getItem("service");
                            const isValid = sessionStorage.getItem("isValid");
                            const stripeSessionId = sessionStorage.getItem("stripeSessionId");
                            const cost = sessionStorage.getItem("cost");

                            if (stripeSessionId != null) {
                            }
                            next(fileActions.addTranslationOption(`${cost}, ${service}`));
                            this.conn.checkUserWordsAllowance().then((resp) => {
                                next(subscriptionActions.setSubData(resp.data));
                                let documentWords = Number(words);
                                let documentPages = Number(pages);

                                if (service == MACHINE_TRANSLATION_NAME) {
                                    if (this.checkIfUserHasWords(documentWords, resp.data)) {
                                        next(controlActions.setShowWordsAllowanceWarningModal(true));
                                        return;
                                    }
                                    if (this.checkIfUserHasPages(documentPages, resp.data)) {
                                        next(controlActions.setShowWordsAllowanceWarningModal(true));
                                        return;
                                    }
                                    next(fileActions.loading(true));
                                    next(controlActions.setLoadingScreenMessage(PREPARING_MACHINE_TRANSLATION));
                                    this.prepareMachineTranslation(api);
                                    return;
                                } else if (service != MACHINE_TRANSLATION_NAME && isValid == "false") {
                                    this.createProjectWithOfferRequired(api, projectId!, service!, true);
                                    next(controlActions.setActiveStep(4));
                                    return;
                                } else {
                                    this.createStripeCheckout(api, service!);
                                }
                            }).catch((err: AxiosError) => {
                                if (err.response?.status == 400 || err.response?.status == 500) {

                                }
                                //api.dispatch(controlActions.showFileLoadingPopUp(false));
                            });
                        }

                        if (step == 4) {
                            const service = sessionStorage.getItem("service");
                            await this.conn.checkIfStripeBuySuccessfully(projectId!).catch((err: AxiosError) => {
                                if (err.response?.status == 400 || err.response?.status == 500) {

                                }
                                //api.dispatch(controlActions.showFileLoadingPopUp(false));
                            });
                            next(controlActions.setLoadingScreenMessage("We are working on it. Be patient, it can take a few moments."));
                            next(fileActions.loading(true));
                            try {
                                service == "1"
                                    ? await this.prepareMachineTranslation(api)
                                    : api.dispatch(
                                        generateCreateProjectAction({
                                            projectId: projectId!,
                                            costEstimationOption: service!
                                        })
                                    );
                            } catch (error) {
                                Sentry.withScope(scope => {
                                    scope.setExtra("User email", `${store.user.email}`);
                                    scope.setExtra("Project id", `${projectId}`);
                                    scope.setExtra("Service", `${service}`);
                                    Sentry.captureMessage(error as string);
                                });
                            }

                        } else {
                            api.dispatch(fileActions.loading(false));
                            api.dispatch(controlActions.setActiveStep(step));
                        }
                    }
                    break;
                }
                case "languages/generatorFetchLanguages": {
                    //await this.conn.getUserLocation().then((r) => (api.dispatch(controlActions.setUserLocationData(r.data))))

                    await this.conn.getLanguages().then((r) => {
                        if (r.length > 0) {
                            next(languagesActions.addLanguages(r));
                        }
                    }).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {

                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });

                    await this.conn.getCountries().then((r) => {
                        if (r.data != undefined) {
                            const index = r.data!.findIndex((x) => x.countryName == "UNDEFINED");
                            const countries = Enumerable.from(r.data).where((x) => x.countryName != "UNDEFINED");
                            api.dispatch(languagesActions.addCountries(countries.toArray()));
                        }
                    }).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {

                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });
                    break;
                }
                case "projects/generateStripePaymentSuccessAction": {
                    const service = sessionStorage.getItem("service");
                    const projectId = sessionStorage.getItem("projectId")!;
                    await this.conn.checkIfStripeBuySuccessfully(projectId).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {

                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });
                    next(controlActions.setLoadingScreenMessage("We are working on it. Be patient, it can take a few moments."));
                    next(fileActions.loading(true));
                    service == "1"
                        ? await this.prepareMachineTranslation(api)
                        : api.dispatch(
                            generateCreateProjectAction({
                                projectId: projectId,
                                costEstimationOption: service!
                            })
                        );
                    break;
                }
                case "control/generateSetAnimationOnNextStepAction": {
                    const a = action as SetAnimationClassAction;
                    api.dispatch(controlActions.setAnimationClass("animate__fadeOutLeft"));
                    setTimeout(() => {
                        api.dispatch(controlActions.setActiveStep(a.payload.activeStep));
                    }, 800);
                    api.dispatch(controlActions.setAnimationClass("animate__fadeInLeft"));

                    break;
                }
                case "control/showFilesSizePopUp": {
                    let a = action as ShowFileSizePopUpAction;
                    api.dispatch(
                        fileActions.addFileError({
                            fileName: a.payload.fileName,
                            content: a.payload.content as string,
                            errorType: ErrorType.processingError,
                            color: "#f44336"
                        })
                    );
                    setTimeout(() => {
                        next(controlActions.showFilesSizePopUp({
                            show: false,
                            fileName: "",
                            title: a.payload.content
                        }));
                    }, 8000);
                    break;
                }
                case "control/showLanguagesPopUp": {
                    let a = action as ShowFileSizePopUpAction;
                    api.dispatch(
                        fileActions.addFileError({
                            fileName: a.payload.fileName,
                            content: a.payload.content as string,
                            errorType: ErrorType.processingError,
                            color: "#f44336"
                        })
                    );
                    setTimeout(() => {
                        next(controlActions.showLanguagesPopUp({ show: false, fileName: "", title: a.payload.title }));
                    }, 8000);
                    break;
                }
                case "control/generateShowErrorAction": {
                    const a = action as ShowErrorAction;

                    //Switch between different errors components
                    switch (a.payload.action) {
                        case "countError": {
                            //Set file count error state to true
                            next(controlActions.showErrorWindow(a.payload.show));
                            //Add animation class to error component
                            next(controlActions.setAnimationClass("animate__fadeInDown"));
                            setTimeout(() => {
                                next(controlActions.setAnimationClass("animate__fadeOut"));
                            }, 8000);
                            setTimeout(() => {
                                next(fileActions.showFileCountError(false));
                            }, 9000);
                            break;
                        }
                        case "serverError": {
                            next(fileActions.loading(false));
                            next(controlActions.showErrorModal(true));
                            next(controlActions.setErrorModalMsg(INTERNAL_SERVER_ERROR));
                            break;
                        }
                    }
                    break;
                }
                case "file/generateConvertFileAction": {
                    let a = action as ConvertFileAction;
                    const model: ConvertFileRequest = {
                        name: a.payload.name,
                        base64String: a.payload.base64String,
                        projectId: store.file.projectId,
                        validateFilter: true,
                        fileSource: 2,
                        pdfType: PdfType.AutoDetect//a.payload.name.toLowerCase().endsWith("pdf") ? 1 : null,
                    };
                    const show = setTimeout(() => {
                        api.dispatch(controlActions.showFileLoadingPopUp(true));
                    }, 5000);
                    const hide = setTimeout(() => {
                        api.dispatch(controlActions.showFileLoadingPopUp(true));
                    }, 5000);

                    await this.conn
                        .checkFileSupport({ files: [a.payload.name], tuft: true })
                        .then((r) => {
                            if (Enumerable.from(r.data.files).all((x) => x.isValide)) {
                                this.conn.convertFile(model)
                                    .then((data) => {
                                        clearTimeout(show);
                                        clearTimeout(hide);
                                        let s = api.getState() as RootState;

                                        /*Add response to store */
                                        if (selectAllFile(store).find((x) => x.fileName == a.payload.name)) {
                                            next(controlActions.showSameFilesNameModal(true));
                                            next(fileActions.deleteDuplicatedFileFromList(a.payload.name));
                                            return;
                                        }
                                        api.dispatch(fileActions.setPdfPagesNumber(data.data.pageCount));
                                        api.dispatch(fileActions.addFile(data.data));
                                    /*Detect language to each file to check if there is difference source languages only for not pdf files */
                                        if (data.data.transUnits.length > 0) {
                                            this.conn
                                                .languageDetect(data.data.transUnits[0].segmentText)
                                                .then((r) => {
                                                    if (!s.languages.isLanguageDetected) {
                                                        api.dispatch(languagesActions.detectLanguage(r.data));
                                                    }
                                                    if (s.languages.isLanguageDetected && s.languages.languageDetectedId != r.data.langId) {
                                                        api.dispatch(
                                                            fileActions.addFileError({
                                                                fileName: model.name,
                                                                content: DIFFERENT_LANGUAGE_WARNING,
                                                                errorType: 1,
                                                                color: "#ffd600"
                                                            })
                                                        );
                                                    }
                                                })
                                            .catch((err: AxiosError) => {
                                                if (err.response?.status == 400 || err.response?.status == 500) {
                                                    api.dispatch(
                                                        fileActions.addFileError({
                                                            fileName: model.name,
                                                            content: "Error while trying to precess your file",
                                                            errorType: ErrorType.processingError,
                                                            color: "#f44336"
                                                        })
                                                    );
                                                }
                                                //api.dispatch(controlActions.showFileLoadingPopUp(false));
                                            });
                                    }
                                }).catch((err: AxiosError) => {
                                    api.dispatch(
                                        fileActions.addFileError({
                                            fileName: model.name,
                                            content: err.message,
                                            errorType: ErrorType.processingError,
                                            color: "#f44336"
                                        })
                                    );
                                });
                            } else {
                                const error = Enumerable.from(r.data.files).first().errorMessage;
                                api.dispatch(
                                    fileActions.addFileError({
                                        fileName: model.name,
                                        content: error,
                                        errorType: ErrorType.processingError,
                                        color: "#f44336"
                                    })
                                );
                            }
                        })
                        .catch((err: AxiosError) => {
                            api.dispatch(
                                fileActions.addFileError({
                                    fileName: model.name,
                                    content: `Upload failed. Refresh the page and try again. Cod-${err.status}`,
                                    errorType: ErrorType.processingError,
                                    color: "#f44336"
                                })
                            );
                            clearTimeout(show);
                            clearTimeout(hide);
                        });

                    break;
                }
                case "file/generateUpdatePdfFileTypeAction": {
                    let a = action as ChangePdfTypeAction;
                    await this.conn.updatePdfFileType(store.file.projectId, a.payload.filedId, 2);
                    break;
                }
                case "languages/generateGetCostEstimation": {
                    next(fileActions.loading(true));
                    next(controlActions.setLoadingScreenMessage(PREPARING_COST_ESTIMATION));
                    const projectId = store.file.projectId;
                    const sourceLang = store.languages.sourceLanguage.toLowerCase();
                    const targetLang = store.languages.targetLanguages.map((x) => x.toLowerCase());
                    this.conn
                        .getCostEstimation({
                            projectId: projectId,
                            targetLanguageCodes: targetLang,
                            sourceLanguageCode: sourceLang
                        })
                        .then((r) => {
                            api.dispatch(fileActions.addCostEstimation(r.data));
                        })
                        .catch((err: AxiosError) => {
                            if (err.response?.status == 400 || err.response?.status == 500) {
                                clearCookies();
                                api.dispatch(
                                    generateShowErrorAction({
                                        show: true,
                                        action: "serverError"
                                    })
                                );
                            }
                        });
                    break;
                }
                case "control/generatePrepareOrderForNotLoggedInUserAction": {
                    let a = action as CostEstimation;

                    next(controlActions.setActiveStep(3));
                    next(fileActions.addTranslationOption(a.payload.price));
                    break;
                }
                case "control/generatePrepareOrderLoggedInUserAction": {
                    let a = action as CostEstimation;
                    next(fileActions.addTranslationOption(a.payload.price));
                    const service = a.payload.price.split(",")[1];
                    /*If total word count less or equal  10000 words and user select MT translation option we dont redirect him to Stripe
                     * just translate files */


                    if (service == HUMAN_TRANSLATION_AND_REVISION) {
                        next(fileActions.loading(true));
                        if (store.file.costEstimations.isValid) {
                            await this.createStripeCheckout(api, service);
                            next(controlActions.setLoadingScreenMessage(REDIRECT_TO_STRIPE));
                        } else {
                            await this.createProjectWithOfferRequired(api, store.file.projectId, service, true);

                            next(controlActions.setLoadingScreenMessage(PROJECT_OFFER_REQUIRED));
                        }

                        return;
                    }
                    /*---------------------------------------------------------------*/

                    if (service == MACHINE_TRANSLATION_NAME) {
                        next(fileActions.loading(true));
                        next(controlActions.setLoadingScreenMessage("Almost done.\n" + "It can take a moment, depending on size of your file(s)."));
                        await this.prepareMachineTranslation(api);
                    } else {
                        if (store.file.costEstimations.isValid) {
                            await this.createStripeCheckout(api, service);

                            next(fileActions.loading(true));
                            next(controlActions.setLoadingScreenMessage(REDIRECT_TO_STRIPE));
                        } else {
                            await this.createProjectWithOfferRequired(api, store.file.projectId, service, true);
                            next(fileActions.loading(true));
                            next(controlActions.setLoadingScreenMessage(PROJECT_OFFER_REQUIRED));
                        }
                    }
                    break;
                }
                case "control/generateCheckUserEmail": {
                    let a = action as CheckEmailAction;
                    next(userActions.setUserEmail(a.payload.email));
                    next(fileActions.loading(true));
                    next(controlActions.setLoadingScreenMessage(CHECKING_EMAIL));

                    /* Send Company Id to microservice in case if user wasn't logged in and create project as anonymous */
                    //await this.conn.setCompanyId(store.file.projectId);

                    await this.conn.checkIfUserGetAccount(a.payload.email).then((r) => {
                        if (r.data) {
                            api.dispatch(controlActions.setAccount(r.data));
                            //addCurrentEditorState()
                            window.location.href =
                                "https://api.textunited.com/jsoneditor/auth/login?ReturnUrl=" +
                                process.env.REACT_APP_SSO_URL +
                                `${encodeURIComponent("?")}step=${store.control.step}${encodeURIComponent("&projectId=")}${store.file.projectId}` +
                                `${encodeURIComponent("&")}targetLang=${store.languages.targetLanguages}` +
                                `${encodeURIComponent("&")}sourceLang=${store.languages.sourceLanguage}` +
                                `${encodeURIComponent("&")}service=${store.file.service}` +
                                `${encodeURIComponent("&")}words=${store.file.totalWordCount}` +
                                `${encodeURIComponent("&")}isValid=${store.file.costEstimations.isValid}` + //${encodeURIComponent(props.projectId)}
                                `${encodeURIComponent("&")}pages=${store.file.pdfFilesPages}` +
                                `${encodeURIComponent("&")}cost=${store.file.translationCost}` +
                                "&checkOnly=N&tuft=true";
                        } else {
                            next(controlActions.setActiveStep(3));
                            next(fileActions.loading(false));
                        }
                    }).catch((err: AxiosError) => {
                        if (err.response?.status == 400 || err.response?.status == 500) {

                        }
                        //api.dispatch(controlActions.showFileLoadingPopUp(false));
                    });
                    // setTimeout(() => {
                    //   next(fileActions.loading(false))
                    // }, 100000)
                    break;
                }
                case "control/generateNextOrderStepAction": {
                    const a = action as OrderNextStepAction;
                    api.dispatch(controlActions.setAnimationClass("animate__fadeOutLeft"));
                    setTimeout(() => {
                        api.dispatch(controlActions.setOrderStep(a.payload.step));
                    }, 0);
                    api.dispatch(controlActions.setAnimationClass("animate__fadeInLeft"));
                    break;
                }
                case "user/generateUserRegisterAction": {
                    next(fileActions.loading(true));
                    const user = store.user;
                    const projectId = store.file.projectId;
                    const userModel: User = {
                        email: user.email,
                        firstName: user.firstName,
                        address: user.address,
                        company: user.company,
                        lastName: user.lastName,
                        type: UserType.PersonalUser,
                    };
                    const req: RegisterUserRequest = {
                        user: userModel,
                        password: user.password,
                        registrationSource: RegistrationSource.Tuft

                    };
                    next(controlActions.setLoadingScreenMessage(CREATING_ACCOUNT));
                    await this.conn
                        .registerNewUser(req)
                        .then((r) => {
                            if (r.data) {
                                let recipients: Array<string> = ["marekp@textunited.com", "dariob@textunited.com"];
                                const model: MailRequest = {
                                    sendAlways: true,
                                    body: `A user with email address: ${user.email} signed up via Tuft.`,
                                    title: "New TUFT User",
                                    emailRecipients: recipients,
                                    attachments: null
                                };

                                this.conn.sendEmail(model);

                                const newUserRegister: RegisterNewUserMail = {
                                    name: `${user.firstName} ${user.lastName}`,
                                    emailRecipients: [user.email],
                                    attachments: [],
                                    compressWhileSerializing: true
                                };
                                this.conn.sendNewUserRegistration(newUserRegister);

                                if (process.env.NODE_ENV == "production") {
                                    gtag("event", "sign_up", { method: "sign up" });
                                }
                                this.conn.checkUserWordsAllowance().then((resp) => {
                                    next(subscriptionActions.setSubData(resp.data));

                                    if (store.file.service == HUMAN_TRANSLATION_AND_REVISION) {
                                        next(fileActions.loading(true));
                                        if (store.file.costEstimations.isValid) {
                                            this.createStripeCheckout(api, store.file.service);
                                            next(controlActions.setLoadingScreenMessage(REDIRECT_TO_STRIPE));
                                        } else {
                                            this.createProjectWithOfferRequired(api, store.file.projectId, store.file.service, true);
                                            next(controlActions.setLoadingScreenMessage(PROJECT_OFFER_REQUIRED));
                                        }
                                        return;
                                    }
                                    /*------------------------------------------------------------------------------------*/
                                    if (store.file.service == MACHINE_TRANSLATION_NAME) {
                                        // if (store.file.totalWordCount > resp.data.wordsAvailable || store.file.pdfFilesPages > resp.data.pdfPagesAvailable) {
                                        //   this.createStripeCheckout(api, store.file.service);
                                        // } else {
                                        //   next(controlActions.setActiveStep(4));
                                        //   next(controlActions.setLoadingScreenMessage(PREPARING_MACHINE_TRANSLATION));
                                        //}
                                        next(controlActions.setActiveStep(4));

                                        this.prepareMachineTranslation(api);
                                    } else if (!store.file.costEstimations.isValid) {
                                        this.createProjectWithOfferRequired(api, store.file.projectId, store.file.service, true);
                                        next(fileActions.loading(true));
                                        next(controlActions.setLoadingScreenMessage(PROJECT_OFFER_REQUIRED));
                                    } else {
                                        this.createStripeCheckout(api, store.file.service);
                                    }
                                }).catch((err: AxiosError) => {
                                    if (err.response?.status == 400 || err.response?.status == 500) {

                                        api.dispatch(
                                            generateShowErrorAction({
                                                show: true,
                                                action: "serverError"
                                            })
                                        );
                                    }
                                });
                                //TagManager.initialize(tagManagerArgs);
                                sessionStorage.setItem("anonymous", "false");
                                sessionStorage.setItem("token", r.data.userToken);
                                sessionStorage.setItem("words", store.file.totalWordCount.toString());
                                sessionStorage.setItem("pdfPages", store.file.pdfFilesPages.toString());
                                next(controlActions.setLoadingScreenMessage(ACCOUNT_WAS_CREATED));
                                this.conn.setCompanyId(projectId);

                                /*TODO: REMOVE THIS LATER*/
                            }
                        })
                        .catch((err: AxiosError) => {
                            if (err.response?.status == 400 || err.response?.status == 500 || err.response?.status == 401) {
                                clearCookies();
                                api.dispatch(
                                    generateShowErrorAction({
                                        show: true,
                                        action: "serverError"
                                    })
                                );
                            }
                        });
                    break;
                }
                case "control/generateCreateProjectAction": {
                    const a = action as CreateProjectAction;
                    next(controlActions.setActiveStep(4));
                    let serviceName = convertCostEstimationToString(a.payload.costEstimationOption);

                    next(fileActions.addTranslationOption(serviceName));
                    const projectId = store.file.projectId;
                    let isPayed = false;
                    await this.conn
                        .checkIfStripeBuySuccessfully(projectId)
                        .then((r) => {
                            isPayed = r.data.paid;
                        })
                        .catch((err: AxiosError) => {
                            if (err.response?.status != 200) {
                                sessionStorage.clear();
                                api.dispatch(generateShowErrorAction({ show: true, action: "serverError" }));
                            }
                        });

                    if (isPayed) {
                        await this.conn
                            .createProject(projectId, serviceName, false)
                            .then((r) => {
                                if (r.status === 200) {
                                    let textUnitedProjectId = r.data.targetLanguages[0].projectId;
                                    next(fileActions.setTextUnitedProjectId(textUnitedProjectId.toString()));
                                    next(fileActions.changeTranslationStatus(true));
                                    next(fileActions.loading(false));
                                }
                            })
                            .catch((err: AxiosError) => {
                                if (err.response?.status != 200) {
                                    sessionStorage.clear();
                                    api.dispatch(generateShowErrorAction({ show: true, action: "serverError" }));
                                }
                            });
                    }
                    break;
                }
                case "control/generateUserRateAction": {
                    const a = action as UserRateAction;

                    const model: MailRequest = {
                        emailRecipients: ["support@textunited.com"],
                        sendAlways: true,
                        title: `User have rated TUFT editor ${a.payload.rate}`,
                        body: `User with ${store.user.email} has been rated TUFT editor`,
                        attachments: null
                    };
                    await this.conn.sendEmail(model);
                    break;
                }
                case "control/generateDownloadMachineTranslationFileAction": {
                    await this.downloadMachineTranslationFile(api);
                    break;
                }
                case "file/generatePayForMachineTranslationAction": {
                    await getProjectCostEstimation();
                    await this.createStripeCheckout(api, store.file.service);
                    break;
                }
                case "control/generateLogoutAction": {
                    this.conn.userLogOut();
                    break;
                }
                case "control/generateLoginAction": {
                    removeSessionStorageItems();
                    document.location.reload();
                    break;
                }
                case "subscription/generateSubscriptionUpgradeBuyAction": {
                    let a = action as SubscribePlanAction;
                    
                    const request: SubscriptionBuyRequest = {
                        cancelUrl: process.env["REACT_APP_ERROR_URL"] as string,
                        successUrl: process.env["REACT_APP_SUCCESS_URL"] as string,
                        subscriptionId: Number(a.payload.subscriptionId),
                        priceYearly: Boolean(a.payload.priceYearly)

                    };
                    await this.conn.subscriptionBuy(request).then((resp) => {
                        if (resp.data.url != "") {
                            sessionStorage.setItem("stripeSessionId", resp.data.stripeSessionId!);
                            document.location.href = resp.data.url;
                        }
                    });
                    break;
                }
                case "subscription/generateSubscriptionBuyAction": {
                    //this is old way to go for one subscription only
                    const request: SubscriptionBuyRequest = {
                        cancelUrl: process.env["REACT_APP_ERROR_URL"] as string,
                        successUrl: process.env["REACT_APP_SUCCESS_URL"] as string,
                        subscriptionId: Number(process.env["REACT_APP_SUBSCRIPTION_ID"]),
                         priceYearly: false // in assumtion of that this plan is for the monthly basis only
                    };

                    await this.conn.subscriptionBuy(request).then((resp) => {
                        if (resp.data.url != "") {
                            sessionStorage.setItem("stripeSessionId", resp.data.stripeSessionId!);
                            document.location.href = resp.data.url;
                        }
                    });
                    break;
                }

                case "user/generateUpdateUserDataAction": {
                    const user = store.user;
                   
                    const userModel: User = {
                        email: user.email,
                        firstName: user.firstName,
                        address: user.address,
                        company: user.company,
                        lastName: user.lastName,
                        type: UserType.PersonalUser,
                    };
                    await this.conn.updateUserData(userModel);

                    break;
                }
                case "user/generateUpdateUserTaxTypeAction": {
              
                    const request: TaxTypeUpdate = {
                        taxNumber: store.user?.taxNumber ?? "",
                        countryId: store.user?.countryId ?? 0,
                        taxTypeCode: store.user?.taxTypeCode ?? ""
                    };
                    await this.conn.setTaxNumber(request).then((resp) => {
                        if (resp.data.startsWith("Invalid")) {
                            next(controlActions.setTaxNumberAlert(true));
                            next(controlActions.setValidationTextField({ field: "taxNumber", validationResult: true }));
                        }
                    });
                    break;
                }
            }
        }
        return next(action);
    };

    async createProjectWithOfferRequired(api: MiddlewareAPI<AppDispatch>, projectId: string, service: string, offerRequired: boolean) {
        /*Set offer required to ms, if user want a offer and we don't send him to Stripe*/
        let option = 0;
        /*Convert service to costEstimationOption*/
        switch (service) {
            case MACHINE_TRANSLATION_NAME:
                option = 1;
                break;
            case MACHINE_TRANSLATION_AND_REVIEW:
                option = 2;
                break;
            case HUMAN_TRANSLATION_AND_REVISION:
                option = 3;
                break;
        }
        //api.dispatch(fileActions.addTranslationOption(service));
        api.dispatch(fileActions.setOfferRequiredStatus(true));
        await this.conn.setOfferRequested(projectId, true);
        await this.conn.setCompanyId(projectId);

        await this.conn.setCostEstimationOption(projectId, option.toString()).then(() => {
            this.conn
                .createProject(projectId, service, offerRequired)
                .then((r) => {
                    if (r.status == 200) {
                        let textUnitedProjectId = r.data.targetLanguages[0].projectId;
                        api.dispatch(fileActions.setTextUnitedProjectId(textUnitedProjectId.toString()));
                        api.dispatch(fileActions.changeTranslationStatus(true));
                        api.dispatch(fileActions.loading(false));
                    }
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status !== 200) {
                        sessionStorage.clear();
                        api.dispatch(generateShowErrorAction({ show: true, action: "serverError" }));
                    }
                });
            api.dispatch(controlActions.setActiveStep(4));
            setTimeout(() => {
            }, 1000);
        });


        // await Promise.all([await this.conn.setOfferRequested(projectId, true), await this.conn.setCompanyId(projectId), await this.conn.setCostEstimationOption(projectId, option.toString())]).then(() => {
        //   this.conn
        //       .createProject(projectId, service, offerRequired)
        //       .then((r) => {
        //         if (r.status == 200) {
        //           let textUnitedProjectId = r.data.targetLanguages[0].projectId;
        //           api.dispatch(fileActions.setTextUnitedProjectId(textUnitedProjectId.toString()));
        //           api.dispatch(fileActions.changeTranslationStatus(true));
        //           api.dispatch(fileActions.loading(false));
        //         }
        //       })
        //       .catch((err: AxiosError) => {
        //         if (err.response?.status !== 200) {
        //           sessionStorage.clear();
        //           api.dispatch(generateShowErrorAction({ show: true, action: "serverError" }));
        //         }
        //       });
        //   api.dispatch(controlActions.setActiveStep(4));
        //   setTimeout(() => {}, 1000);
        // });
    }

    /**
     * Download machine translation zip file
     */
    async downloadMachineTranslationFile(api: MiddlewareAPI<AppDispatch, any>) {
        let store = api.getState();
        const projectId = store.file.projectId;
        Sentry.captureMessage(`Download  machine translation for project: ${projectId}`);
        this.conn.machineTranslationDownload(projectId).then((r) => {
            const blob = new Blob([r.data]);
            try {
                saveAs(blob, "translation.zip");
            } catch (error) {
                api.dispatch(controlActions.showFileDownloadingModal(false));
            }
        }).catch((err: AxiosError) => {
            api.dispatch(controlActions.showFileDownloadingModal(false));
        });
    }

    /**
     * This func prepare and create MT for user
     * @param api - Apps middleware instance
     */
    async prepareMachineTranslation(api: MiddlewareAPI<AppDispatch, any>) {
        //api.dispatch(fileActions.loading(false));
        const store = api.getState() as RootState;
        const projectId = store.file.projectId;
        const projectInfo = await this.conn.getProjectInfo(store.file.projectId);
        try {
            if (projectInfo.data[0].wordcount < store.user.wordsAllowance || projectInfo.data[0].pageCount < store.user.pdfPagesAllowance) {

                Sentry.captureMessage(
                    `Words total: ${projectInfo.data[0].wordcount}, 
      \nPDF total: ${projectInfo.data[0].pageCount}
      \nWords available: ${store.subscription.subData.wordsAvailable}
      \nPDF pages available: ${store.subscription.subData.pdfPagesAvailable}`);

                const wordsTotalOk = projectInfo.data[0].wordcount == 0 || store.subscription.subData.wordsAvailable >= projectInfo.data[0].wordcount;
                const pdfTotalOk = projectInfo.data[0].pageCount == 0 || store.subscription.subData.pdfPagesAvailable >= projectInfo.data[0].pageCount;
                Sentry.captureMessage(`Words total ok: ${wordsTotalOk}, PDF total ok: ${pdfTotalOk}`);
                if (wordsTotalOk && pdfTotalOk) {
                    api.dispatch(controlActions.setLoadingScreenMessage(PREPARING_MACHINE_TRANSLATION));
                    Sentry.captureMessage(`Start machine translation for project: ${projectId}`);
                    await this.startMachineTranslation(api, projectId).catch((err: AxiosError) => {
                        api.dispatch(generateShowErrorAction({ show: true, action: "serverError" }));
                    });
                } else {
                    await this.checkIfProjectWasPaid(api, projectId);
                }
            } else {
                await this.checkIfProjectWasPaid(api, projectId);
            }
        } catch (error) {
            Sentry.captureException(error);
            Sentry.withScope(scope => {
                scope.setExtra("User email", `${store.user.email}`);
                scope.setExtra("Project id", `${projectId}`);
                Sentry.captureMessage(`MT preparing failed with:\n${error as string}`);
            });
        }
    }

    /**
     * Create Stripe checkout
     * @param api - Apps middleware instance
     * @param service - Translation service which was selected by user eg.(Instant - MT, Rapid - MT + Human Review)
     */
    async createStripeCheckout(api: MiddlewareAPI<AppDispatch, any>, service?: string) {
        if (process.env.NODE_ENV === "production") {
            gtag("event", "select_content", { content_type: "redirect_to_stripe", item_id: "stripe" });
        }
        let store = api.getState();
        sessionStorage.setItem("words", store.file.totalWordCount.toString());
        sessionStorage.setItem("pages", store.file.pdfFilesPages.toString());
        let option = 0;
        /*Convert service to costEstimationOption*/
        switch (service?.trim()) {
            case MACHINE_TRANSLATION_NAME:
                option = 1;
                break;
            case MACHINE_TRANSLATION_AND_REVIEW:
                option = 2;
                break;
            case HUMAN_TRANSLATION_AND_REVISION:
                option = 3;
                break;
        }

        const prepareModel: PrepareStripeCheckoutRequest = {
            projectId: store.file.projectId,
            costEstimationOption: option,
            successUrl: process.env["REACT_APP_SSO_URL"]!,
            cancelUrl: process.env["REACT_APP_SSO_URL"] + "?error=true",
            title: service!,
            type: "TUFT"
        };

        await this.conn
            .prepareStripeCheckout(prepareModel)
            .then((r) => {
                sessionStorage.setItem("stripeSessionId", r.data.stripeSessionId!);
                document.location.replace(r.data.url);
            })
            .catch((err: AxiosError) => {
                if (err.response?.status == 400 || err.response?.status == 500 || err.response?.status == 401) {
                    clearCookies();
                    api.dispatch(generateShowErrorAction({ show: true, action: "serverError" }));
                }
            });
    }

    /**
     * This method start signalR hub and sending requst to MT microservice to initiate process of Machine Translation,
     * and waiting for response from microservice sent through signalR
     * @param api
     * @param projectId
     */
    async startMachineTranslation(api: MiddlewareAPI<AppDispatch, any>, projectId: string) {
        Sentry.captureMessage(`Start machine translation for project: ${projectId}`);
        await hubConnection.start().then(() => {
            this.conn.machineTranslation(projectId, hubConnection.connectionId!).then(() => {
                api.dispatch(controlActions.setActiveStep(4));
            });
        });
    }

    /**
     * This method checks if project was already paid
     * @param api - instance of Middleware object
     * @param projectId - id of the project, generated by client side
     */
    async checkIfProjectWasPaid(api: MiddlewareAPI<AppDispatch, any>, projectId: string) {
        try{
            let store = api.getState();
            let isPayed = await this.conn.checkIfStripeBuySuccessfully(projectId);
            if (isPayed.data.paid) {
                const sessionId = sessionStorage.getItem("stripeSessionId");
                purchaseEvent(sessionId!, isPayed.data.amount, isPayed.data.title, "Translation");
                await this.startMachineTranslation(api, projectId);
            } else {
                api.dispatch(controlActions.setLoadingScreenMessage("Redirecting to Stripe"));
                await this.createStripeCheckout(api, store.file.service);
            }
        }
        catch (error){
            Sentry.captureException(error);
            Sentry.withScope(scope => {
                scope.setExtra('User email', `${api.getState().user.email}`);
                scope.setExtra('Project id', `${projectId}`);
                Sentry.captureMessage(`Error when try to check if project was paid ${error as string}`);
            });
        }

    }

    async getUserAllowance(api: MiddlewareAPI<AppDispatch>) {
        this.conn.checkUserWordsAllowance().then((resp) => {
            api.dispatch(subscriptionActions.setSubData(resp.data));
        });
    }

    checkIfUserHasWords(words: number, userAllowance: CheckUserWordsAllowanceResponse): boolean {
        if (words != 0) {
            return words >= userAllowance.wordsAvailable;
        }
        return false;
    }

    checkIfUserHasPages(pages: number, userAllowance: CheckUserWordsAllowanceResponse): boolean {
        if (pages != 0) {
            return pages >= userAllowance.pdfPagesAvailable;
        }
        return false;
    }
}
